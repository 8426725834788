(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbCard.wbFaq.controller:FaqCtrl
   *
   * @description
   *
   */
  angular
    .module('wbFaq')
    .controller('FaqCtrl', FaqCtrl);

  function FaqCtrl() {
  }
}());
